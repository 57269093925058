import React from "react";

import Layout from "../components/layout";

const GitHubReadme = ({ pageContext }) => {
  const { html } = pageContext;

  return (
    <Layout>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </Layout>
  );
};

export default GitHubReadme;
